import React from "react";
import {CollectionActionsProps, EntityReference, useSnackbarController} from "@firecms/core";
import { Button } from "@firecms/ui";
import axios from "axios";

export function FixAssetPlaybackActions({ selectionController, context, collectionEntitiesCount, tableController }: CollectionActionsProps) {
	const onClick = async (event: React.MouseEvent) => {
		const dataSource = context.dataSource;
		const promises = [];
		tableController.data.forEach(d => {
			if (!d.values.playback_id && d.values.asset) {
				promises.push(
					axios.get(`https://us-central1-seirogai-tv.cloudfunctions.net/getPlayback?asset_id=${d.values.asset}`)
						.then(resp => {
							dataSource.saveEntity({
								status: "existing",
								path: d.path,
								entityId: d.id,
								values: {
									playback_id: resp.data.data.playback_ids[0].id,
								}
							});
						}));
			}
		});
		await Promise.all(promises);
	};

	return (
		<Button onClick={onClick}
		        color="primary"
		        variant={"text"}>
			Fix Asset Playback
		</Button>
	);

}
