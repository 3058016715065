import React from "react";
import {PropertyPreviewProps} from "@firecms/cloud";
import MuxPlayer from "@mux/mux-player-react";

export default function CustomMuxFileUploadPreview(
{
     value, property, size
}: PropertyPreviewProps<string>) {
	return (
		<MuxPlayer disableTracking={true} disableCookies={true} disablePictureInPicture={true}
			playbackId={value}
			streamType="on-demand"
		/>
	);
}
