import React, { useEffect, useState } from "react";
import {FieldProps, useDataSource} from "@firecms/cloud";
import MuxUploader from "@mux/mux-uploader-react";
import axios from "axios";

interface CustomMuxFileUploadFieldProps {}

export default function CustomMuxFileUploadField({
     property,
     value,
     setValue,
     setFieldValue,
     customProps,
     touched,
     includeDescription,
     showError,
     error,
     isSubmitting,
     context, // the rest of the entity values here
     ...props
 }: FieldProps<string, CustomMuxFileUploadFieldProps>) {
     const [oldValue, setOldValue] = useState(value);
    const [authUrl, setAuthUrl] = useState("");
    const [isLoadingUrl, setIsLoadingUrl] = useState(false);
    const [uploadId, setUploadId] = useState("");
    const cfUrl = "https://us-central1-seirogai-tv.cloudfunctions.net";
    useEffect(() => {
        if (!isLoadingUrl && authUrl === "") {
            setIsLoadingUrl(true);
            axios.post(`${cfUrl}/createUploadUrl`).then(response => {
                setAuthUrl(response.data.data.url);
                setIsLoadingUrl(false);
                setUploadId(response.data.data.id);
            }).catch(err => {
                console.error(err);
            });
        }
    }, [authUrl, isLoadingUrl]);

    useEffect(() => {
        if (isSubmitting && oldValue !== "" && oldValue !== value) {
            axios.delete(`${cfUrl}/deleteAsset?asset_id=${oldValue}`)
            .then(() => {
                console.log("old asset removed");
                setOldValue(value);
            });
        }
    }, [isSubmitting, oldValue, value]);

    useEffect(() => {
        if (isSubmitting && oldValue !== value) {
            axios.post(`${cfUrl}/savePlayback`, {
                asset_id: value,
                path: context.path,
                id: context.entityId
            });
        }
    }, [isSubmitting, value, oldValue])

    return (
        <>
            {
                isLoadingUrl && (<h2>Loading Uploader. Please wait.</h2>)
            }
            {
                !isLoadingUrl && <MuxUploader endpoint={authUrl} onSuccess={async () => {
                    try {
                        const uploadData = await axios.get(`${cfUrl}/getUploadedAsset?upload_id=${uploadId}`);
                        const assetId = uploadData.data.data.asset_id;
                        setValue(assetId);
                    } catch (e) {
                        console.error(e);
                    }
                }}></MuxUploader>
            }
        </>

    );

}
