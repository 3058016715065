import {buildCollection, buildEntityCallbacks, EntityOnDeleteProps} from "@firecms/core";
import CustomMuxFileUploadField from "../form_fields/CustomMuxFileUploadField";
import CustomMuxFileUploadPreview from "../form_fields/CustomMuxFileUploadPreview";
import axios from "axios";
import {FixAssetPlaybackActions} from "./collection_actions/FixAssetPlaybackActions";

const videoCallbacks = buildEntityCallbacks({
	onDelete(entityDeleteProps: EntityOnDeleteProps) {
		if (entityDeleteProps.entity.values.asset && entityDeleteProps.entity.values.asset !== "") {
			axios.delete(`https://us-central1-seirogai-tv.cloudfunctions.net/deleteAsset?asset_id=${entityDeleteProps.entity.values.asset}`)
			.then(() => {
				console.log("asset removed");
			})
			.catch((e) => {
				console.error(e);
			});
		}
	}
});
export const videosCollection = buildCollection({
	id: "videos",
	name: "Videos",
	singularName: "Video",
	path: "videos",
	description: "Contain video information. Can be included in season as episode or in title directly as a sequel",
	editable: true,
	icon: "videocam",
	group: "Video Management",
	Actions: FixAssetPlaybackActions,
	callbacks: videoCallbacks,
	properties: {
		title: {
			name: "title",
			path: "titles",
			propertyConfig: "reference",
			dataType: "reference",
		},
		video_title: {
			name: "video title",
			propertyConfig: "text_field",
			dataType: "string",
			validation: {
				required: true,
			},
		},
		description: {
			multiline: true,
			dataType: "string",
			propertyConfig: "multiline",
			name: "description",
		},
		asset: {
			name: "asset",
			dataType: "string",
			Field: CustomMuxFileUploadField,
			customProps: {}
		},
		playback_id: {
			name: "playback id",
			dataType: "string",
			readOnly: true,
			Preview: CustomMuxFileUploadPreview,
		},
		photo: {
			propertyConfig: "file_upload",
			dataType: "string",
			name: "photo",
			storage: {
				storagePath: "/video_thumb/",
				fileName: "{entityId}.{file.ext}",
				storeUrl: true,
			},
		},
		duration: {
			dataType: "number",
			validation: {
				required: true,
			},
			propertyConfig: "number_input",
			name: "duration",
		},
		release_date: {
			name: "release_date",
			mode: "date_time",
			dataType: "date",
			propertyConfig: "date_time",
		},
	},
	subcollections: [],
});
